@font-face {
    font-family: "DIN Next W01";
    src: url("/fonts/da088ecc-a53c-4831-a95b-6fd81b5b338a.eot?#iefix");
    src: url("/fonts/da088ecc-a53c-4831-a95b-6fd81b5b338a.eot?#iefix") format("eot"),
    url("/fonts/44e7b0fa-6c8d-43c2-b19e-f1e3ce9ea57c.woff2") format("woff2"),
    url("/fonts/c5a7f89e-15b6-49a9-8259-5ea665e72191.woff") format("woff"),
    url("/fonts/868c94b5-ca49-46b7-b62b-297f8d861125.ttf") format("truetype"),
    url("/fonts/e3145f65-29b5-4f7f-8c6e-2dc135021b6a.svg#e3145f65-29b5-4f7f-8c6e-2dc135021b6a") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "DIN Next W01";
    src: url("/fonts/fa5ed46b-792d-4f47-894e-fa587cf4e1cf.eot?#iefix");
    src: url("/fonts/fa5ed46b-792d-4f47-894e-fa587cf4e1cf.eot?#iefix") format("eot"), 
         url("/fonts/03008527-67bd-478e-98e3-3b56dd9a6520.woff2") format("woff2"), 
         url("/fonts/557f817d-9c25-4a23-baaf-a3cf84a7cd7c.woff") format("woff"), 
         url("/fonts/92ed1479-1d40-4a94-8baf-6abd88b17afa.ttf") format("truetype"), 
         url("/fonts/36ae914b-b535-47cd-b3d2-8c43073d8634.svg#36ae914b-b535-47cd-b3d2-8c43073d8634") format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "EuroPlate";
    src: url("/fonts/EuroPlate.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
